/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    trash2: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M14 3a.7.7 0 01-.037.225l-1.684 10.104A2 2 0 0110.305 15H5.694a2 2 0 01-1.973-1.671L2.037 3.225A.7.7 0 012 3c0-1.105 2.686-2 6-2s6 .895 6 2M3.215 4.207l1.493 8.957a1 1 0 00.986.836h4.612a1 1 0 00.986-.836l1.493-8.957C11.69 4.689 9.954 5 8 5s-3.69-.311-4.785-.793"/>',
    },
});
